import React from 'react'

const Logo = ({ size = 32, className = '' }) => {
  return (
    <svg 
      width={size} 
      height={size} 
      viewBox="0 0 100 100" 
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="logoGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#2563EB" />
          <stop offset="100%" stopColor="#1D4ED8" />
        </linearGradient>
        <filter id="glow">
          <feGaussianBlur stdDeviation="1.5" result="coloredBlur"/>
          <feMerge>
            <feMergeNode in="coloredBlur"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      {/* Fondo circular con gradiente */}
      <circle 
        cx="50" 
        cy="50" 
        r="45" 
        fill="url(#logoGradient)"
        opacity="0.08"
      />
      {/* Letra R estilizada */}
      <path
        d="M35 30 C35 30 35 70 35 70 
           M35 30 C35 25 40 25 45 25 L60 25 
           C68 25 70 30 70 35 L70 38
           C70 45 65 48 60 48 L35 48 
           M58 48 L70 70"
        stroke="url(#logoGradient)"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        filter="url(#glow)"
      />
      {/* Punto decorativo */}
      <circle
        cx="70"
        cy="70"
        r="3"
        fill="url(#logoGradient)"
        filter="url(#glow)"
      />
      {/* Línea decorativa */}
      <path
        d="M30 75 L75 75"
        stroke="url(#logoGradient)"
        strokeWidth="2"
        strokeLinecap="round"
        opacity="0.3"
      />
    </svg>
  )
}

export default Logo 
import React from 'react'
import { HiSparkles, HiHeart } from 'react-icons/hi'
import { HiOutlineBriefcase, HiOutlineGlobeAlt } from 'react-icons/hi'
import Logo from './Logo'

const Footer = () => {
  const otherSites = [
    {
      name: "Consultoría Tecnológica",
      url: "https://reynergonzalez.com",
      description: "Servicios profesionales en tecnología",
      icon: <HiOutlineBriefcase className="text-blue-500" size={20} />
    },
    {
      name: "Applicativa",
      url: "https://applicativa.xyz",
      description: "Empresa de desarrollo de software",
      icon: <HiOutlineGlobeAlt className="text-blue-500" size={20} />
    }
  ]

  return (
    <footer className="bg-gradient-to-b from-white to-gray-50/50">
      <div className="max-w-7xl mx-auto px-6 py-16">
        <div className="flex flex-col items-center space-y-16">
          {/* Logo */}
          <a href="/" className="group flex items-center space-x-3 transition-transform hover:scale-105 duration-300">
            <div className="relative w-8 h-8">
              <Logo className="w-full h-full" />
              <div className="absolute inset-0 bg-blue-500/10 rounded-xl blur-xl group-hover:bg-blue-500/20 transition-all duration-300"></div>
            </div>
            <span className="text-base font-light tracking-wide text-gray-700">Reyner</span>
          </a>

          {/* Mensaje */}
          <p className="text-sm text-gray-500 max-w-md text-center leading-relaxed">
            Creando tecnología con propósito para inspirar y potenciar el cambio positivo en el mundo.
          </p>

          {/* Enlaces a otros sitios */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-2xl">
            {otherSites.map((site, index) => (
              <a
                key={index}
                href={site.url}
                target="_blank"
                rel="noopener noreferrer"
                className="group p-6 rounded-2xl bg-white hover:bg-gray-50 shadow-lg shadow-blue-900/5 hover:shadow-xl hover:shadow-blue-900/10 transition-all duration-300"
              >
                <div className="flex items-start space-x-4">
                  <div className="p-3 rounded-xl bg-blue-50 group-hover:bg-blue-100/80 transition-colors duration-300">
                    {site.icon}
                  </div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-800 group-hover:text-gray-900">
                      {site.name}
                    </h3>
                    <p className="text-xs text-gray-500 mt-1">
                      {site.description}
                    </p>
                  </div>
                  <div className="ml-auto opacity-0 group-hover:opacity-100 transition-all duration-300 transform group-hover:translate-x-0 translate-x-4">
                    <HiSparkles className="text-blue-400" size={16} />
                  </div>
                </div>
              </a>
            ))}
          </div>

          {/* Línea decorativa */}
          <div className="w-32 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent"></div>

          {/* Copyright */}
          <div className="flex flex-col items-center space-y-4">
            <div className="flex items-center space-x-2 text-sm text-gray-400">
              <span>Creado con</span>
              <HiHeart className="text-blue-400" size={16} />
              <span>por</span>
              <a 
                href="https://reyner.cr" 
                className="text-gray-600 hover:text-blue-600 transition-colors duration-300"
              >
                Reyner
              </a>
            </div>
            <p className="text-xs text-gray-400">
              © {new Date().getFullYear()} Todos los derechos reservados
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer 
import React, { useState, useEffect } from 'react'
import { BsSun, BsSunset, BsMoonStars } from 'react-icons/bs'
import { HiSparkles, HiHeart, HiLightBulb, HiGlobe, HiStar, HiOutlinePuzzle } from 'react-icons/hi'
import { RiMindMap } from 'react-icons/ri'
import morningImage from '../assets/morning.svg'
import afternoonImage from '../assets/afternoon.svg'
import nightImage from '../assets/night.svg'

const MESSAGES = {
  morning: 'Cada amanecer trae consigo nuevas oportunidades para crear, innovar y hacer la diferencia.',
  afternoon: 'En la calma de la tarde, encontramos el espacio perfecto para desarrollar ideas que transforman el mundo.',
  night: 'La noche nos inspira a soñar con las infinitas posibilidades que la tecnología nos brinda.'
}

const GREETINGS = {
  morning: 'Buenos días',
  afternoon: 'Buenas tardes',
  night: 'Buenas noches'
}

const Hero = () => {
  const [greeting, setGreeting] = useState('')
  const [timeIcon, setTimeIcon] = useState(null)
  const [message, setMessage] = useState('')
  const [mounted, setMounted] = useState(false)
  const [timeOfDay, setTimeOfDay] = useState('morning')

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    const getTimeOfDay = () => {
      const hour = new Date().getHours()
      if (hour >= 5 && hour < 12) return 'morning'
      if (hour >= 12 && hour < 18) return 'afternoon'
      return 'night'
    }

    setTimeOfDay(getTimeOfDay())
    const interval = setInterval(() => setTimeOfDay(getTimeOfDay()), 1000 * 60)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (timeOfDay === 'morning') {
      setGreeting('Buenos días')
      setMessage('Cada amanecer trae consigo nuevas oportunidades para crear, innovar y hacer la diferencia.')
      setTimeIcon(
        <BsSun className="inline-block mb-1 mr-4 animate-morning-shine text-amber-500 drop-shadow-lg" size={40} />
      )
    } else if (timeOfDay === 'afternoon') {
      setGreeting('Buenas tardes')
      setMessage('En la calma de la tarde, encontramos el espacio perfecto para desarrollar ideas que transforman el mundo.')
      setTimeIcon(
        <BsSunset className="inline-block mb-1 mr-4 animate-afternoon-float text-orange-400 drop-shadow-lg" size={40} />
      )
    } else {
      setGreeting('Buenas noches')
      setMessage('La noche nos inspira a soñar con las infinitas posibilidades que la tecnología nos brinda.')
      setTimeIcon(
        <BsMoonStars className="inline-block mb-1 mr-4 animate-night-twinkle text-indigo-400 drop-shadow-lg" size={36} />
      )
    }
  }, [timeOfDay])

  const inspirationalSections = [
    {
      icon: <HiHeart className="text-rose-500" size={32} />,
      title: "Inspira",
      description: "Cada persona tiene el poder de inspirar y motivar a otros a través de sus acciones y su ejemplo.",
      color: "rose"
    },
    {
      icon: <HiLightBulb className="text-amber-500" size={32} />,
      title: "Crea",
      description: "La creatividad nos permite transformar ideas en realidades que impactan positivamente en el mundo.",
      color: "amber"
    },
    {
      icon: <HiGlobe className="text-emerald-500" size={32} />,
      title: "Conecta",
      description: "Las conexiones genuinas nos permiten crecer, aprender y construir juntos un mejor futuro.",
      color: "emerald"
    },
    {
      icon: <HiStar className="text-blue-500" size={32} />,
      title: "Trasciende",
      description: "Cada día es una oportunidad para dejar una huella positiva en la vida de los demás.",
      color: "blue"
    }
  ]

  const journeySections = [
    {
      title: "Desarrollo",
      description: "Creando soluciones tecnológicas que simplifican y mejoran la vida de las personas",
      icon: <HiOutlinePuzzle className="text-blue-400" size={24} />,
      gradient: "from-blue-400/20 to-emerald-400/20"
    },
    {
      title: "Innovación",
      description: "Explorando nuevas formas de resolver desafíos a través de la tecnología",
      icon: <HiLightBulb className="text-amber-400" size={24} />,
      gradient: "from-amber-400/20 to-rose-400/20"
    },
    {
      title: "Comunidad",
      description: "Construyendo espacios donde la tecnología une y potencia a las personas",
      icon: <HiGlobe className="text-emerald-400" size={24} />,
      gradient: "from-emerald-400/20 to-blue-400/20"
    },
    {
      title: "Impacto",
      description: "Generando cambios positivos a través de soluciones tecnológicas significativas",
      icon: <RiMindMap className="text-purple-400" size={24} />,
      gradient: "from-purple-400/20 to-rose-400/20"
    }
  ]

  const getTimeBasedStyles = () => {
    const morningClouds = [...Array(8)].map((_, i) => ({
      left: `${Math.random() * 120 - 20}%`,
      top: `${Math.random() * 40}%`,
      width: Math.random() > 0.5 ? '8rem' : '6rem',
      height: Math.random() > 0.5 ? '3rem' : '2rem',
      delay: `${Math.random() * 20}s`,
      duration: `${60 + Math.random() * 20}s`
    }));

    const nightStars = [...Array(50)].map((_, i) => ({
      left: `${Math.random() * 100}%`,
      top: `${Math.random() * 100}%`,
      size: Math.random() > 0.8 ? '2px' : '1px',
      delay: `${Math.random() * 3}s`
    }));

    if (timeOfDay === 'morning') {
      return {
        bgGradient: 'from-amber-50 via-orange-50 to-yellow-50',
        accentColor: 'text-amber-500',
        titleColor: 'text-gray-800',
        textColor: 'text-gray-800',
        textColorSecondary: 'text-gray-600',
        cardBg: 'bg-white',
        cardHoverBg: 'hover:bg-gray-50',
        particles: (
          <>
            {morningClouds.map((cloud, i) => (
              <div
                key={`cloud-${i}`}
                className="absolute"
                style={{
                  left: cloud.left,
                  top: cloud.top,
                  animation: `float-slow ${cloud.duration} linear infinite`,
                  animationDelay: cloud.delay
                }}
              >
                <div 
                  className="bg-amber-50/30 rounded-full blur-xl"
                  style={{
                    width: cloud.width,
                    height: cloud.height
                  }}
                />
              </div>
            ))}
          </>
        ),
        image: morningImage,
        imageClass: 'opacity-10 scale-75 rotate-12'
      };
    } else if (timeOfDay === 'afternoon') {
      return {
        bgGradient: 'from-blue-50 via-sky-50 to-indigo-50',
        accentColor: 'text-blue-500',
        titleColor: 'text-gray-800',
        textColor: 'text-gray-800',
        textColorSecondary: 'text-gray-600',
        cardBg: 'bg-white',
        cardHoverBg: 'hover:bg-gray-50',
        particles: (
          <>
            {[...Array(6)].map((_, i) => (
              <div
                key={`cloud-${i}`}
                className="absolute"
                style={{
                  left: '-20%',
                  top: `${Math.random() * 30}%`,
                  animation: `float-slow ${80 + Math.random() * 40}s linear infinite`,
                  animationDelay: `${Math.random() * -30}s
`                }}
              >
                <div className="w-40 h-12 bg-orange-100/20 rounded-full blur-xl" />
              </div>
            ))}
          </>
        ),
        image: afternoonImage,
        imageClass: 'opacity-10 -scale-x-75 scale-y-75'
      };
    } else {
      return {
        bgGradient: 'from-indigo-100 via-purple-50 to-slate-50',
        accentColor: 'text-indigo-600',
        titleColor: 'text-gray-800',
        textColor: 'text-gray-700',
        textColorSecondary: 'text-gray-600',
        cardBg: 'bg-white/90',
        cardHoverBg: 'hover:bg-white',
        shadowColor: 'shadow-indigo-100/50',
        particles: (
          <>
            {nightStars.map((star, i) => (
              <div
                key={`star-${i}`}
                className="absolute"
                style={{
                  left: star.left,
                  top: star.top,
                  animation: `twinkle-random 3s ease-in-out infinite`,
                  animationDelay: star.delay
                }}
              >
                <div 
                  className="bg-white/40 rounded-full"
                  style={{
                    width: star.size,
                    height: star.size
                  }}
                />
              </div>
            ))}
            <div className="absolute top-[15%] right-[20%] animate-moon-glow">
              <div className="w-20 h-20 bg-indigo-50/30 rounded-full blur-xl" />
            </div>
          </>
        ),
        image: nightImage,
        imageClass: 'opacity-10 scale-75 -rotate-12'
      };
    }
  };

  const timeStyles = getTimeBasedStyles()

  return (
    <main className={`min-h-screen bg-gradient-to-b ${timeStyles.bgGradient} transition-all duration-1000`}>
      <section className="min-h-screen flex items-center justify-center relative overflow-hidden">
        {/* Efectos de fondo */}
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,currentColor_1px,transparent_0)] [background-size:40px_40px] opacity-[0.03] pointer-events-none" />
        
        {/* Partículas específicas del momento */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          {timeStyles.particles}
        </div>

        {/* Contenido principal con estilos dinámicos */}
        <div className={`text-center space-y-12 max-w-2xl px-4 relative ${timeStyles.shadowColor}`}>
          {/* Imagen decorativa */}
          <div className="absolute -z-10 inset-0 pointer-events-none">
            <img 
              src={timeStyles.image} 
              alt="" 
              className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-xl transition-all duration-1000 ${timeStyles.imageClass}`}
            />
          </div>

          <div className="relative">
            <h1 className={`text-5xl md:text-6xl font-extralight tracking-tight flex items-center justify-center ${
              timeStyles.accentColor
            }`}>
              {timeStyles.icon}
              <span className="animate-fade-in">{greeting}</span>
            </h1>
          </div>

          <div className="space-y-8 relative">
            <div className="relative inline-block">
              <p className="text-2xl md:text-3xl font-light text-gray-700 animate-fade-in animation-delay-500">
                mi nombre es{' '}
                <span className="font-normal relative inline-block group">
                  Reyner
                  <span className="absolute -bottom-1 left-0 w-full h-px bg-gradient-to-r from-transparent via-current to-transparent transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500"></span>
                </span>
              </p>
            </div>

            <p className="text-xl text-gray-500 leading-relaxed animate-fade-in animation-delay-1000">
              Gracias por visitar mi sitio...
            </p>

            <div className="relative p-8 bg-white/50 backdrop-blur-sm rounded-2xl shadow-xl animate-fade-in animation-delay-1500">
              <div className="absolute -left-2 -top-2">
                <HiSparkles className="text-amber-300" size={16} />
              </div>
              <p className="text-xl md:text-2xl font-light text-gray-600 italic">
                {message}
              </p>
              <div className="absolute -right-2 -bottom-2">
                <HiSparkles className="text-amber-300" size={16} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Sección Principal */}
      <section className="py-20 px-4 relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,gray_1px,transparent_0)] [background-size:40px_40px] opacity-[0.03]" />
        
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-20">
            <span className="text-sm font-medium text-gray-500 tracking-wider uppercase">Bienvenido a mi espacio</span>
            <h2 className={`text-4xl md:text-5xl font-light mb-6 ${timeStyles.titleColor}`}>
              Creando Experiencias Significativas
            </h2>
            <p className={`text-xl max-w-2xl mx-auto leading-relaxed ${timeStyles.textColorSecondary}`}>
              Un lugar donde la tecnología se encuentra con el propósito y la innovación con el impacto social.
            </p>
          </div>

          {/* Grid de valores */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 relative">
            {inspirationalSections.map((section, index) => (
              <div
                key={index}
                className={`group p-8 rounded-2xl ${timeStyles.cardBg} shadow-lg shadow-blue-900/5 hover:shadow-xl hover:shadow-blue-900/10 transition-all duration-500 relative overflow-hidden ${timeStyles.cardHoverBg}`}
              >
                <div className={`absolute inset-0 bg-${section.color}-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500`} />
                <div className="relative z-10">
                  <div className="mb-6 flex justify-center">
                    <div className={`w-16 h-16 rounded-full bg-${section.color}-500/10 flex items-center justify-center group-hover:scale-110 transition-transform duration-500`}>
                      {section.icon}
                    </div>
                  </div>
                  <h3 className={`text-xl font-medium mb-4 ${timeStyles.textColor}`}>
                    {section.title}
                  </h3>
                  <p className={timeStyles.textColorSecondary}>
                    {section.description}
                  </p>
                </div>
                <div className="absolute -bottom-1 left-0 w-full h-1 bg-gradient-to-r from-transparent via-current to-transparent opacity-0 group-hover:opacity-20 transition-opacity duration-500" />
              </div>
            ))}
          </div>

          {/* Sección de áreas */}
          <div className="mt-32 relative">
            <div className="absolute inset-0 bg-gradient-to-b from-white/0 via-gray-50/50 to-white/0" />
            
            <div className="relative">
              <div className="text-center mb-16">
                <h3 className="text-3xl font-light text-gray-800 mb-4">
                  Áreas de Impacto
                </h3>
                <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                  Explorando diferentes formas de crear valor y generar cambios positivos.
                </p>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
                {journeySections.map((item, index) => (
                  <div
                    key={index}
                    className={`group relative p-8 rounded-2xl ${timeStyles.cardBg} shadow-lg hover:shadow-xl transition-all duration-500 overflow-hidden ${timeStyles.cardHoverBg}`}
                  >
                    <div className={`absolute inset-0 bg-gradient-to-br ${item.gradient} opacity-0 group-hover:opacity-30 transition-opacity duration-500`} />
                    <div className="relative z-10">
                      <div className="flex items-center gap-4 mb-4">
                        <div className={`p-3 rounded-xl ${timeStyles.cardBg} shadow-sm group-hover:scale-110 transition-transform duration-500`}>
                          {item.icon}
                        </div>
                        <h4 className={`text-xl font-medium ${timeStyles.textColor}`}>
                          {item.title}
                        </h4>
                      </div>
                      <p className={timeStyles.textColorSecondary}>
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Mensaje final inspiracional */}
          <div className="mt-32 text-center relative">
            <div className="absolute top-0 left-1/2 -translate-x-1/2 w-px h-20 bg-gradient-to-b from-transparent via-gray-200 to-transparent" />
            <div className="relative inline-block bg-white/80 backdrop-blur-xl px-8 py-6 rounded-2xl shadow-lg shadow-blue-900/5">
              <HiSparkles className="absolute -left-3 -top-3 text-blue-400" size={24} />
              <p className="text-2xl font-light text-gray-700 max-w-3xl mx-auto leading-relaxed">
                "La innovación y la creatividad son las herramientas con las que construimos 
                un futuro mejor para todos."
              </p>
              <HiSparkles className="absolute -right-3 -bottom-3 text-blue-400" size={24} />
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Hero 
import React, { useState, useEffect } from 'react'
import Logo from './Logo'

const Header = () => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <header className={`fixed w-full top-0 z-50 transition-all duration-700 ${
      scrolled ? 'bg-white/70 backdrop-blur-xl shadow-lg shadow-blue-900/5' : 'bg-transparent'
    }`}>
      <nav className="max-w-7xl mx-auto px-6 py-5">
        <div className="flex justify-between items-center">
          <a href="/" className="group flex items-center space-x-3 transition-transform hover:scale-105 duration-300">
            <div className="relative w-10 h-10">
              <Logo className="w-full h-full" />
              <div className="absolute inset-0 bg-blue-500/10 rounded-xl blur-xl group-hover:bg-blue-500/20 transition-all duration-300"></div>
            </div>
            <span className="text-lg font-light tracking-wide text-gray-700">
              Reyner
            </span>
          </a>
        </div>
      </nav>
    </header>
  )
}

export default Header 